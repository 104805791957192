import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import anime from 'animejs';

class Blob extends Component {
  componentDidMount() {
    this.blob404(this.refs.blob404);
  }

  componentDidUpdate() {
    this.blob404(this.refs.blob404);
  }

  blob404 = el => {
    anime({
      targets: el,
      d: [
        {
          value: this.props.keyframe2,
        },
        {
          value: this.props.keyframe3,
        },
      ],
      easing: 'easeOutQuad',
      direction: 'alternate',
      duration: 10000,
      loop: true,
    });
  };

  render() {
    const { keyframe1 } = this.props;

    return (
      <svg fill="inherit" width="1475" height="1475">
        <path ref="blob404" d={keyframe1} />
      </svg>
    );
  }
}

Blob.propTypes = {
  keyframe1: PropTypes.string,
  keyframe2: PropTypes.string,
  keyframe3: PropTypes.string,
};

export default Blob;
