import React from 'react';
import helpers from '../../../lib/helpers';
import TextUnderline from '../TextUnderline';
import * as styles from './footer.module.scss';

const Footer = ({ footer }) => (
  <footer>
    <div className={styles.inner}>
      <div className={styles.titleAndPageLinks}>
        <h3 className={styles.title}>
          <TextUnderline
            headline={footer.headline}
            headlineUnderline={footer.headlineUnderline}
            underlineTheme="canvasMint"
          />
        </h3>

        <ul className={styles.pageLinks}>
          {footer.links &&
            footer.links.map((linkTxt, i) => {
              const linkObj = helpers.explodeText(linkTxt);
              return (
                <li key={i} className={styles.itemValue}>
                  <a href={linkObj.value}>{linkObj.label}</a>
                </li>
              );
            })}
        </ul>

        <ul className={[styles.pageLinks, styles.additionalPageLinks].join(' ')}>
          {footer.additionalLinks &&
            footer.additionalLinks.length > 0 &&
            footer.additionalLinks.map((linkTxt, i) => {
              const linkObj = helpers.explodeText(linkTxt);
              return (
                <li key={i} className={styles.itemValue}>
                  <a href={linkObj.value}>{linkObj.label}</a>
                </li>
              );
            })}
        </ul>
      </div>

      <div className={[styles.list, styles.contact].join(' ')}>
        <div className={styles.listTitleWrapper}>
          <h6 className={styles.listTitle}>Contact</h6>
        </div>
        <dl>
          <div className={styles.item}>
            <dt className={styles.itemTitle}>Phone</dt>
            <dd>{footer.phone}</dd>
          </div>

          <div className={styles.item}>
            <dt className={styles.itemTitle}>Investment Inquiries</dt>
            <dd>
              <a href="https://airtable.com/shrrAEDb8LKp48VNC">Contact Us</a>
            </dd>
          </div>

          <div className={styles.item}>
            <dt className={styles.itemTitle}>Social</dt>
            {footer.socialLinks &&
              footer.socialLinks.map((socialTxt, i) => {
                const socialObj = helpers.explodeText(socialTxt);
                return (
                  <dd key={i} className={styles.itemValue}>
                    <a href={socialObj.value}>{socialObj.label}</a>
                  </dd>
                );
              })}
          </div>
        </dl>
      </div>

      <div className={[styles.list, styles.offices].join(' ')}>
        <div className={styles.listTitleWrapper}>
          <h6 className={styles.listTitle}>Office</h6>
        </div>
        {footer.offices &&
          footer.offices.map((officeTxt, i) => {
            const officeObj = helpers.explodeText(officeTxt);
            return (
              <dl key={i}>
                <div className={styles.item}>
                  <dt className={styles.itemTitle}>{officeObj.label}</dt>
                  <dd>{officeObj.value}</dd>
                </div>
              </dl>
            );
          })}
      </div>
    </div>
  </footer>
);

export default Footer;
