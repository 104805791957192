import React from 'react';
import * as PropTypes from 'prop-types';

import Underline from './Underline';

const TextUnderline = ({ headline, headlineUnderline, url, externalUrl, underlineTheme }) => {
  if (!headlineUnderline) {
    return <span>{headline}</span>;
  }

  return (
    <span>
      {headline.split(headlineUnderline).reduce((prev, current, i) => {
        if (!i) {
          return [current];
        }
        return prev.concat(
          <Underline
            key={i}
            text={headlineUnderline}
            url={url}
            externalUrl={externalUrl}
            className={underlineTheme}
          />,
          current,
        );
      }, [])}
    </span>
  );
};

TextUnderline.propTypes = {
  headline: PropTypes.string.isRequired,
  headlineUnderline: PropTypes.string,
  url: PropTypes.string,
  externalUrl: PropTypes.string,
};

export default TextUnderline;
