import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import StagingNotice from '../components/StagingNotice';
import Footer from '../components/Footer';

import 'normalize.css';
import '../styles/base/_base.scss';

const DefaultLayout = ({ children }) => {
  return (
    <main className="overflow-all">
      <a className="skip-to-main" href="#main">
        Skip to main content
      </a>

      <StaticQuery
        query={graphql`
          query LayoutQuery {
            site {
              siteMetadata {
                netlifyBuildTime
              }
            }
            contentfulFooter {
              headline
              headlineUnderline
              email
              phone
              socialLinks
              offices
              links
              additionalLinks
            }
          }
        `}
        render={data => (
          <>
            {data.site.siteMetadata.netlifyBuildTime && (
              <StagingNotice netlifyBuildTime={data.site.siteMetadata.netlifyBuildTime} />
            )}
            {data.site.siteMetadata.netlifyBuildTime && (
              <Helmet>
                <meta name="robots" content="noindex" />
              </Helmet>
            )}
            {children}
            <Footer footer={data.contentfulFooter} />
          </>
        )}
      />
    </main>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.object,
};

export default DefaultLayout;
