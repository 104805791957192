import React from 'react';
import * as PropTypes from 'prop-types';

import Nav from '../Nav';

const Theme = ({ theme, children, transition }) => {
  const style = {
    ...(transition ? transition.style : {}),
  };

  return (
    <div style={style}>
      <Nav theme={theme} />
      <div id="main" />
      {children}
    </div>
  );
};

Theme.propTypes = {
  theme: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  transition: PropTypes.object,
};

export default Theme;
