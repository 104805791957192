import React, { Component } from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
import BlobsBackground from '../Blobs/Background';
import Close from '../../svgs/Close';
import Logo from '../../svgs/Logo';
import LogoWhite from '../../svgs/LogoWhite';
import Menu from '../../svgs/Menu';

import '../../styles/experimental/illustration-text-stroke.scss';
import TextUnderline from '../TextUnderline';
import * as styles from './nav.module.scss';

class Nav extends Component {
  state = {
    menuVisible: false,
    mainEl: null,
  };

  componentDidMount() {
    try {
      document.addEventListener('keydown', this.catchEscKey, false);
      window.addEventListener('resize', this.catchResize, false);

      this.setState({ mainEl: document.getElementsByTagName('main') });
    } catch (e) {
      // document & window isn't found when Gatsby builds
    }
  }

  componentDidUpdate = () => {
    if (this.state.mainEl.length) {
      const mainEl = this.state.mainEl[0];

      this.state.menuVisible
        ? mainEl.classList.remove('overflow-all')
        : mainEl.classList.add('overflow-all');
    }
  };

  componentWillUnmount() {
    this.setState({ menuVisible: false });

    try {
      document.removeEventListener('keydown', this.catchEscKey, false);
      window.removeEventListener('resize', this.catchResize, false);
    } catch (e) {
      // document & window isn't found when Gatsby builds
    }
  }

  catchResize = () => {
    const NAV_BREAKPOINT = 770;

    if (document.body.clientWidth > NAV_BREAKPOINT) {
      this.setState({ menuVisible: false });
    }
  };

  catchEscKey = evt => {
    if (evt.keyCode === 27 && this.state.menuVisible) {
      this.toggleMenu();
    }
  };

  toggleMenu = () => {
    this.setState({ menuVisible: this.state.menuVisible === true ? false : true });
  };

  render() {
    const { menuVisible } = this.state;
    const { theme } = this.props;

    return (
      <div className={styles.navigationWrapper}>
        <nav className={theme ? [styles.nav, styles[theme]].join(' ') : styles.nav}>
          <Link aria-label="Home" className={styles.logo} to="/">
            {theme ? <LogoWhite /> : <Logo />}
          </Link>
          <div className={[styles.menu, menuVisible ? styles.visible : styles.hidden].join(' ')}>
            <div
              role={'button'}
              className={styles.menuControls}
              onClick={this.toggleMenu}
              onKeyDown={this.toggleMenu}
              tabIndex={0}>
              <button aria-label="Open menu" className={styles.menuOpen}>
                <Menu />
              </button>
              <button aria-label="Close menu" className={styles.menuClose}>
                <Close />
              </button>
            </div>

            <div className={styles.blobs}>
              <BlobsBackground />
            </div>
            <ul className={styles.list}>
              <li className={[styles.item, styles.mobileOnlyItem].join(' ')}>
                <Link
                  className={[styles.link, 'text-stroke-nav'].join(' ')}
                  activeClassName={styles.active}
                  to="/"
                  partiallyActive={true}>
                  Home
                </Link>
              </li>

              <li className={styles.item}>
                <Link
                  className={[styles.link, 'text-stroke-nav'].join(' ')}
                  activeClassName={styles.active}
                  to="/portfolio"
                  partiallyActive={true}>
                  Portfolio
                </Link>
              </li>

              <li className={styles.item}>
                <Link
                  className={[styles.link, 'text-stroke-nav'].join(' ')}
                  activeClassName={styles.active}
                  to="/conviction-areas"
                  partiallyActive={true}>
                  Conviction Areas
                </Link>
              </li>

              <li className={styles.item}>
                <Link
                  className={[styles.link, 'text-stroke-nav'].join(' ')}
                  activeClassName={styles.active}
                  to="/thoughts"
                  partiallyActive={true}>
                  Thoughts
                </Link>
              </li>

              <li className={styles.item}>
                <Link
                  className={[styles.link, 'text-stroke-nav'].join(' ')}
                  activeClassName={styles.active}
                  to="/press"
                  partiallyActive={true}>
                  Press
                </Link>
              </li>

              <li className={styles.item}>
                <Link
                  className={[styles.link, 'text-stroke-nav'].join(' ')}
                  activeClassName={styles.active}
                  to="/about"
                  partiallyActive={true}>
                  About
                </Link>
              </li>

              <li className={[styles.item, styles.notMobileItem].join(' ')}>
                <a
                  className={[styles.link, 'text-stroke-nav'].join(' ')}
                  href="https://talent.emcap.com/">
                  Talent
                </a>
              </li>

              <li className={[styles.item, styles.mobileOnlyItem].join(' ')}>
                <a
                  className={[styles.link, 'text-stroke-nav'].join(' ')}
                  href="https://talent.emcap.com/">
                  Portfolio Jobs
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

Nav.propTypes = {
  theme: PropTypes.string,
};

export default Nav;
