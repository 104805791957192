import React from 'react';
import Blob from '../Blob';
import * as styles from './background.module.scss';

const Background = () => (
  <div>
    <div className={styles.blob1}>
      <Blob
        keyframe1="M215.031287,78.1379485 C327.718787,-111.643302 495.001102,151.492872 845.063602,187.070997 C1195.1261,222.649122 1351.44951,433.441143 1351.44951,581.706768 C1351.44951,729.972393 1190.88166,1124.25262 630.200159,1217.50627 C69.5186532,1310.75992 114.951666,840.900884 79.3735417,650.088384 C43.7954167,459.275884 102.343787,267.919198 215.031287,78.1379485 Z"
        keyframe2="M318.721761,565.405483 C128.940511,319.264858 347.386015,-77.032017 722.903576,143.436733 C1098.42114,363.905483 1199.14557,346.030483 1150.00301,776.780483 C1100.86045,1207.53048 939.409261,1254.54611 756.174886,1361.57736 C634.018636,1432.93152 488.200928,1367.78569 318.721761,1166.13986 C130.315511,896.155483 508.503011,811.546108 318.721761,565.405483 Z"
        keyframe3="M219.892768,76.7277187 C332.580268,-113.053531 609.802328,158.639168 959.864828,194.217293 C1309.92733,229.795417 1270.43591,610.692782 1270.43591,758.958407 C1270.43591,907.224032 923.378239,1203.474 557.534489,1248.099 C191.690739,1292.724 407.557422,864.008347 371.979297,673.195847 C336.401172,482.383347 107.205268,266.508969 219.892768,76.7277187 Z"
      />
    </div>
    <div className={styles.blob2}>
      <Blob
        keyframe1="M737,506.043288 C849.6875,316.262038 750.234147,54.700781 1100.29665,90.278906 C1450.35915,125.857031 1351.0625,357.777663 1351.0625,506.043288 C1351.0625,654.308913 1466.1404,1219.03125 1100.29665,1263.65625 C734.452897,1308.28125 501.015625,1098.76563 465.4375,907.953125 C429.859375,717.140625 624.3125,695.824538 737,506.043288 Z"
        keyframe2="M584.244183,365.25649 C696.931683,175.47524 654.258838,49.4333677 1004.32134,85.0114927 C1354.38384,120.589618 1419.19211,360.502621 1419.19211,508.768246 C1419.19211,657.033871 1171.73384,1346.7605 805.890089,1391.3855 C440.046339,1436.0105 188.074489,992.224494 152.496364,801.411994 C116.918239,610.599494 471.556683,555.03774 584.244183,365.25649 Z"
        keyframe3="M196.635868,402.633742 C309.323368,212.852492 336.890625,101.625 686.953125,137.203125 C1037.01563,172.78125 852.146602,275.61539 1087.01562,598.015625 C1321.88465,920.41586 1302.62723,1182.80796 741.945729,1276.06161 C181.264222,1369.31526 376.94389,1052.85702 341.365765,862.044525 C305.78764,671.232025 83.9483676,592.414992 196.635868,402.633742 Z"
      />
    </div>
    <div className={styles.blob3}>
      <Blob
        keyframe1="M414.264301,499.507766 C526.951801,309.726516 473.449412,144.548221 893.090208,50.328125 C1312.731,-43.8919707 1468.125,54.203125 1468.125,202.46875 C1468.125,350.734375 1409.98438,1369.59375 1044.14062,1414.21875 C678.296875,1458.84375 357.666823,1343.8107 322.088698,1152.9982 C286.510573,962.1857 301.576801,689.289016 414.264301,499.507766 Z"
        keyframe2="M328.602798,130.61871 C612.388707,-135.991353 595.92413,266.560561 998.522111,307.478058 C1401.12009,348.395555 1485.83163,457.498211 1432.97128,757.383449 C1380.11092,1057.26869 1173.13822,1308.01619 528.312674,1415.26484 C-116.51287,1522.51349 283.2948,1161.63758 242.377303,942.188977 C201.459806,722.740373 44.8168891,397.228772 328.602798,130.61871 Z"
        keyframe3="M249.96875,521.203125 C371.544913,316.452145 805.903091,0.0686377138 1183.57813,38.453125 C1561.25316,76.8376123 1309.51563,462.148725 1309.51563,622.109375 C1309.51563,782.070025 1555.95109,1366.74566 1161.25,1414.89062 C766.548909,1463.03559 258.978237,1261.30107 220.59375,1055.4375 C182.209263,849.573926 128.392587,725.954105 249.96875,521.203125 Z"
      />
    </div>
  </div>
);

export default Background;
