// extracted by mini-css-extract-plugin
export var inner = "footer-module--inner--2Qn1b";
export var titleAndPageLinks = "footer-module--titleAndPageLinks--3GZ8Z";
export var list = "footer-module--list--dGOeE";
export var pageLinks = "footer-module--pageLinks---3dsS";
export var additionalPageLinks = "footer-module--additionalPageLinks--23jk7";
export var pageLinksGreen = "footer-module--pageLinksGreen--2F6ZR";
export var contact = "footer-module--contact--34eRb";
export var offices = "footer-module--offices--NaGNd";
export var listTitleWrapper = "footer-module--listTitleWrapper--7-ZMh";
export var listTitle = "footer-module--listTitle--3kcn9";
export var title = "footer-module--title--3v0SS";
export var item = "footer-module--item--3muv2";
export var itemTitle = "footer-module--itemTitle--2ZrDx";
export var itemValue = "footer-module--itemValue--1jJXO";