import React, { Component } from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';

import * as styles from './underline.module.scss';

class Underline extends Component {
  state = {
    width: null,
    height: null,
  };

  componentDidMount() {
    this.measure();
    window.addEventListener('resize', this.measure);
  }

  componentDidUpdate() {
    this.measure();
    window.addEventListener('resize', this.measure);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.width !== nextState.width || this.state.height !== nextState.height;
  }

  measure = () => {
    if (this.refs.text) {
      this.setState({
        width: this.refs.text.clientWidth,
        height: this.refs.text.clientHeight,
      });

      this.draw();
    }
  };

  draw = () => {
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext('2d');

    const underlineColor = this.props.className
      ? this.colorFromUnderlineTheme(styles[this.props.className])
      : this.colorFromUnderlineTheme(styles.canvasTan);

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.beginPath();
    ctx.moveTo(0, this.state.height * 0.9);
    ctx.bezierCurveTo(
      this.state.width * 0.5,
      this.state.height * 0.8,
      this.state.width * 0.5,
      this.state.height * 1,
      this.state.width,
      this.state.height * 0.9,
    );
    ctx.strokeStyle = underlineColor;
    ctx.lineWidth = 2.5;
    ctx.stroke();
  };

  colorFromUnderlineTheme = className => {
    const tmp = document.createElement('div');
    tmp.style.cssText = 'position:fixed;left:-100px;top:-100px;width:1px;height:1px';
    tmp.className = className;
    document.body.appendChild(tmp);
    const color = getComputedStyle(tmp).getPropertyValue('color');
    document.body.removeChild(tmp);
    return color;
  };

  renderUnderline = () => {
    const { width, height } = this.state;

    return (
      <u ref="text" className={styles.underlineContainer}>
        {this.props.text}
        <canvas className={'canvas'} ref="canvas" width={width} height={height} />
      </u>
    );
  };

  render() {
    return this.props.url ? (
      <Link to={this.props.url} className={styles.underlineLink}>
        {this.renderUnderline()}
      </Link>
    ) : this.props.externalUrl ? (
      <a href={this.props.externalUrl} className={styles.underlineLink}>
        {this.renderUnderline()}
      </a>
    ) : (
      this.renderUnderline()
    );
  }
}

Underline.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
};

export default Underline;
